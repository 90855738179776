.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.heading1 {
		color: var(--color-primary);
	}

	.facility-section,
	#lastviewed-units,
	.feedback-section-bl {
		.container {
			max-width: 1170px;
		}
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}


	.heading3 {
		font-size: 24px;
		font-weight: 700;
		color: var(--color-primary);
		line-height: 36px;
	}


	.bg-grey-light {
		background-color: #F4F2F0;
	}

	p {
		a {
			text-decoration: underline;
			color: var(--color-grey-dark);
		}
	}

	.haus-sitebar {
		.address {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			i {
				margin-right: 5px;
			}

			span {
				font-size: 18px;
				font-weight: 500;
				color: #0E2369;
				line-height: 30px;
			}
		}

		.rating {
			background-color: #fff;
			color: #0E2369;
			padding: 0px 4px;
			display: inline-block;
		}

		.property-bl {
			margin-top: 38px;
			list-style: none;
			padding-left: 0;

			@media (min-width:550px) {
				column-count: 2;
			}

			.property {
				position: relative;
				padding-left: 70px;
				-webkit-box-align: center;
				align-items: center;
				margin-bottom: 10px;
				min-height: 50px;
				display: flex;
				align-items: center;

				.prop-icon {
					position: absolute;
					left: 0;
					top: 0;
					width: 50px;
					height: 50px;
					color: var(--color-white);
					background-color: var(--color-primary);
					font-size: 30px;
					border-radius: 10px;
					display: flex;
					justify-content: center;
					align-items: center
				}

				.prop-label {
					font-size: 16px;
					color: var(--color-primary);

				}
			}
		}
	}

	#lastviewed-units {
		.bg-grey-light {
			background-color: var(--color-white);

			.heading1 {
				text-align: center;
				display: block;
			}
		}
	}

	.unit-feedbacks-v1 {
		.ptitle {
			display: inline-block;
		}

		.ratings {
			display: flex;
			flex-wrap: wrap;

			.rating {
				margin: 0 30px 15px 0;

				.fa {
					color: var(--color-primary)
				}
			}
		}

		.feedbacks {

			clear: left;
			padding-top: 15px;

			.feedback {
				border-left: 3px solid var(--color-grey-normal);
				background-color: var(--color-grey-light);
				padding: 10px;
				font-style: italic;


				.rating {
					font-size: var(--font-size-sm);
				}

				.guest-text {
					clear: left;
					padding-top: 10px;
					font-size: var(--font-size-md);
				}

				.author {
					font-size: var(--font-size-sm);
				}

				.comment {
					padding-left: 10px;
					border-left: 3px dotted var(--color-red);
					font-size: var(--font-size-md);
				}

				.agency {
					padding-bottom: 10px;
				}
			}
		}
	}
}