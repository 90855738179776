.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */

	.team-foto {
		height: 600px;
		width: auto;

		@media(max-width:768px) {
			height: 500px;
		}

	}

	.about-us-button {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	.team-members {
		display: flex;
		flex-wrap: wrap;

		.member {
			width: 50%;
			border: 15px solid transparent;

			@media (max-width:767px) {
				width: 100%;
			}

			.member-image {
				object-position: center;
			}
		}

		.member-info {
			text-align: center;
			background-color: var(--color-white);
			object-position: center;
			height: 100%;
			padding: 15px !important;

			justify-content: flex-start !important;

			.name {
				font-weight: 600;
			}

			.resort {
				font-size: var(--font-size-md);
				color: var(--color-grey-dark);
				margin-bottom: 10px;
			}

			.phone {
				font-size: var(--font-size-md);
			}

			.email {
				font-size: var(--font-size-md);
			}

		}
	}




}