.index-faq-v1 {
	.h3 {
		font-size: 1.45rem;
	}

	.card {
		background-color: transparent;
		/* border: 1px solid var(--color-grey-normal); */
		border: none;

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}