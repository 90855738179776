.search-seo-text-v1 {
	padding-top: 60px;
	background: var(--color-white);

	@media (max-width:767px) {
		padding-top: 20px;
	}

	ul {
		padding: 20px;
		margin: inherit;
		list-style: inherit;
	}
}