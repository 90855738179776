.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.content-banner-bl {
		background-image: url(RESOURCE/img/16808926.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		display: block;
		position: relative;

		&.bg-lesetipp2 {
			background-image: url(RESOURCE/img/banner-dog.jpg);
			background-position: center;
			background-attachment: fixed;

			.container {
				min-height: 280px;
			}
		}

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			inset: 0;
		}

		.container {
			min-height: 330px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 1;
			padding: 15px;

			.content-bl {
				max-width: 800px;
				text-align: center;
				background-color: rgba(255, 255, 255, 0.56);
				border-radius: 10px;
				padding: 38px 28px;
				position: relative;

				h3 {
					color: rgba(68, 68, 68, 1);
					font-weight: bold;
					margin-bottom: 23px;
					font-size: 29px;
				}

				a {
					text-decoration: none;
				}
			}
		}


	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1380px;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}