.other-v1 {
	margin-top: var(--page-margin-top);

	.grey-section-bl {
		background: rgba(244, 242, 240, 1);
		padding: 30px 0;
	}

	.mid-grey {
		background: #D9D9D9;
	}

	&.anreise-v1 {
		.keine-img {
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		}

		p {
			a {
				text-decoration: underline;
				font-weight: normal;
				color: var(--font-color-main);

				&:hover {
					text-decoration: none;
					color: var(--link-font-color);
				}
			}
		}

		.heading1 {
			&.style2 {
				font-size: 36px;
				font-weight: 600;
				line-height: 42.19px;
				color: rgba(68, 68, 68, 1);
				display: block;
				text-transform: inherit;
			}
		}

		.style {
			border: 1px solid rgba(68, 68, 68, 1);
			background-color: rgba(229, 229, 229, 1);
			padding: 5px;

			p {
				margin-bottom: 0px;
			}
		}

		.content-banner-bl {
			background-image: url(RESOURCE/img/16808926.jpg);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
			position: relative;

			&.bg-lesetipp {
				background-image: url(RESOURCE/img/banner2-summer-1980.jpg);
				background-position: bottom;

				.container {
					min-height: 280px;
				}
			}

			&:after {
				content: "";
				background-color: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
				width: 100%;
				height: 100%;
				position: absolute;
				inset: 0;
			}

			.container {
				min-height: 330px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				z-index: 1;
				padding: 15px;

				.content-bl {
					max-width: 800px;
					text-align: center;
					background-color: rgba(255, 255, 255, 0.56);
					border-radius: 10px;
					padding: 38px 28px;
					position: relative;

					h3 {
						color: rgba(68, 68, 68, 1);
						font-weight: bold;
						margin-bottom: 23px;
						font-size: 29px;
					}
				}
			}


		}
	}

	.inner-banner {
		&.eigentumer {
			background-image: url(RESOURCE/img/banner-eigentumer-new.jpg);
		}
	}

	.inner-banner {
		&.gueste {
			background-image: url(RESOURCE/img/banner-gaeste.jpg);
		}
	}

	.offer-box {
		position: relative;
		margin-bottom: 30px;
		padding: 30px 15px 15px;

	}

	.offer-blog {
		.offer {
			margin-bottom: 15px;
			background-color: #fff;
			background-color: var(--color-white);
			width: 100%;
			border-radius: 0px;
			margin-bottom: 40px;


			.img-col {
				min-width: 0;
				min-height: 0;
				position: relative;

				@media(min-width:992px) {

					-webkit-box-flex: 1;
					flex: 1 1 0;
				}

				.img-content {
					padding-top: 50%;
					width: 100%;
				}

				.img-thumbnail {
					width: 100% !important;
					height: 100% !important;
					padding: 0;
					border: none;
					border-radius: 0;
					position: absolute;
					top: 0;
				}

				.ribbon {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					overflow: hidden;
					width: 110px;
					height: 110px;
					text-align: right;
				}

				.ribbon span {
					font-size: 16px;
					font-weight: 500;
					color: #fff;
					text-transform: uppercase;
					text-align: center;
					line-height: 25px;
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					width: 160px;
					display: block;
					background: var(--color-secondary);
					/*	box-shadow: 0 3px 10px -5px #000; */
					position: absolute;
					top: 31px;
					left: -39px;

				}

			}

			.info-col {
				position: relative;
				padding: 26px;
				background-color: #ededed;


				h4 {
					font-size: 1.5rem;
				}

				.date {
					margin-top: -10px;
				}

				.description {
					margin-top: 40px;
				}

				.button {

					text-align: right;

					@media(max-width:992px) {
						margin-top: 10px;
						margin-bottom: 10px;
					}

					.fa {
						padding-left: 10px;
					}
				}
			}
		}
	}
}

.eigentumer-slide {

	background: #f4f4f4;
	padding: 50px;
	margin-bottom: 80px;
	position: relative;
	border-radius: 5px;

	.owl-item {
		border: 0px solid #323335;
		padding: 00px;
		margin-bottom: 20px;

		@media (max-width:767px) {
			padding: 20px;
		}

		>div {
			h3 {
				font-weight: bold;
				margin-bottom: 20px;
				color: var(--color-primary);
			}
		}
	}

	.owl-dots {
		text-align: center;
		margin-bottom: 0;
		position: absolute;
		bottom: -40px;
		left: 0;
		right: 0;

		.owl-dot {
			display: inline-block;
			width: 10px;
			height: 10px;
			margin: 4px;
			border-radius: 100%;
			outline: none;
			cursor: pointer;
			opacity: .2;
			background-color: #000;

			&.active {
				opacity: 1;
			}
		}


	}
}



.responsive-cols {
	.col {
		flex-basis: inherit;
		-webkit-box-flex: inherit;
		flex-grow: inherit;
		max-width: 20%;

		@media (max-width:1200px) {
			max-width: 33.33%;
		}

		@media (max-width:992px) {
			max-width: 50%;
		}

		@media (max-width:767px) {
			max-width: 100%;

		}
	}
}